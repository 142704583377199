import React, { useState, useEffect, Suspense, useRef } from "react";
import "./index.css";
import { DimensionsContext, LoadingIndicator, useDimensions } from "@common";
import { Layout, Main, Cookies } from "@components";

const MainPage = React.lazy(() => import("../components/main-page"));

const BasicHomePage = () => {
  let defaultCookiesAcceptance;

  if (typeof window !== "undefined") {
    defaultCookiesAcceptance = localStorage.getItem("cookies-accept");
  }

  const [isCookiesAccepted, setCookiesAccepted] = useState(
    defaultCookiesAcceptance
  );
  const [isFontsLoading, setFontsLoading] = useState(true);

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontsLoading(false);
    });
  }, []);

  const pageReferences = {
    START: useRef(),
    ABOUT: useRef(),
    OFFER: useRef(),
    DOCUMENTS: useRef(),
    REAL_ESTATE_HINT: useRef(),
    CONTACT: useRef(),
  };

  const scrollToNode = (node) => {
    node.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollFunctions = {
    scrollToAbout: () => scrollToNode(pageReferences.ABOUT),
    scrollToStart: () => scrollToNode(pageReferences.START),
    scrollToOffer: () => scrollToNode(pageReferences.OFFER),
    scrollToDocuments: () => scrollToNode(pageReferences.DOCUMENTS),
    scrollToRealEstateHint: () => scrollToNode(pageReferences.REAL_ESTATE_HINT),
    scrollToContact: () => scrollToNode(pageReferences.CONTACT),
  };

  const {
    scrollToStart,
    scrollToAbout,
    scrollToOffer,
    scrollToDocuments,
    scrollToRealEstateHint,
    scrollToContact,
  } = scrollFunctions;

  const navigationOptions = [
    { sectionStringCode: "start", scrollFunction: scrollToStart },
    { sectionStringCode: "about", scrollFunction: scrollToAbout },
    { sectionStringCode: "offer", scrollFunction: scrollToOffer },
    { sectionStringCode: "documents", scrollFunction: scrollToDocuments },
    {
      sectionStringCode: "realEstateHint",
      scrollFunction: scrollToRealEstateHint,
    },
    { sectionStringCode: "contact", scrollFunction: scrollToContact },
  ];

  return (
    <>
      <LoadingIndicator
        isLoading={isFontsLoading && typeof window === "undefined"}
      />
      <Cookies
        setCookiesAccepted={setCookiesAccepted}
        isCookiesAccepted={isCookiesAccepted}
      />
      <Layout
        navigationOptions={navigationOptions}
        pageReferences={pageReferences}
      >
        <Main parentRef={pageReferences.START} />
        <main className="main-container">
          <Suspense fallback={<LoadingIndicator isLoading={true} />}>
            <MainPage pageReferences={pageReferences} />
          </Suspense>
        </main>
      </Layout>
    </>
  );
};

const IndexPage = (props) => {
  if (typeof window === "undefined") {
    return null;
  }

  const dimensions = useDimensions();

  return (
    <DimensionsContext.Provider value={dimensions}>
      <BasicHomePage {...props} />
    </DimensionsContext.Provider>
  );
};

export default IndexPage;

// export const Head = () => <title>Strona główna | Wycena nieruchomości Magdalena Panasiuk</title>
